export const CATEGORIES = {
  cronaca: {
    slug: 'cronaca',
    title: 'Cronaca',
  },
  economia: {
    slug: 'economia',
    title: 'Economia',
  },
  cultura: {
    slug: 'cultura',
    title: 'Cultura',
  },
  sport: {
    slug: 'sport',
    title: 'Sport',
  },
};
