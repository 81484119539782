<template>
  <div class="SearchInputContainer">
    <form v-show="showInput" @submit="onSubmit">
      <input
        v-model="searchQuery"
        autofocus
        class="SearchInput"
        :filled="Boolean(searchQuery)"
        placeholder="Ricerca"
      />
      <button type="submit" class="Submit">
        <img alt="Cerca" src="/assets/icons/search.svg" />
      </button>
      <input type="submit" hidden />
    </form>
    <button v-show="!showInput" class="Search" @click="onOpenSearch">
      <img alt="Cerca" src="/assets/icons/search.svg" />
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
      showInput: false,
    };
  },
  computed: {
    action() {
      return `/search?q=${this.searchQuery}`;
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.searchQuery !== '') {
        window.document.location = `/search?q=${this.searchQuery}`;
      } else {
        this.showInput = false;
      }

      return false;
    },
    onOpenSearch() {
      this.showInput = true;
    },
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';

.SearchInputContainer {
  display: flex;
  align-items: center;
}

form {
  display: flex;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.Submit {
  margin-left: 5px;
}

.SearchInput {
  border: 2px solid $grey;
  border-radius: 10px;
  padding: 10px;
  outline: none;
  max-width: 150px;

  &::placeholder {
    margin: 5px;
  }

  &[filled='true'] {
    border-color: $main-color;
  }
}
</style>
