<template>
  <header class="Header" :opened="drawerOpened">
    <nuxt-link to="/">
      <Logo />
    </nuxt-link>
    <div class="DesktopMenu">
      <slot name="menu"></slot>
    </div>
    <div class="Hamburger" :opened="drawerOpened" @click="toggleDrawer">
      <span />
      <span />
      <span />
    </div>
  </header>
</template>

<script>
import Logo from '~/components/Logo.vue';

export default {
  components: {
    Logo,
  },
  props: {
    drawerOpened: Boolean,
  },
  methods: {
    toggleDrawer() {
      this.$emit('ham-click');
    },
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';
@import '~/assets/media.pcss';

.Header {
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  height: $header-height;
  padding: $main-padding;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: $under-shadow;
  background-color: $white;
  z-index: 2;
  transition: border-radius 300ms;
  border-radius: 0 0 20px 20px;
}

.SubHeader {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: $main-padding;
}

.Logo {
  display: block;
  height: 60px;
}

.Logo:hover {
  animation: HueRotate 1s infinite linear;
}

.Hamburger {
  span {
    display: block;
    width: 33px;
    height: 4px;
    position: relative;
    background: $main-color;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:nth-of-type(1) {
      transform-origin: 0% 0%;
    }

    &:nth-of-type(2) {
      transform-origin: 0% 100%;
    }

    &:not(:nth-of-type(1)) {
      margin-top: 5px;
    }
  }

  @media (--medium) {
    display: none;
  }
}

.Header[opened] {
  border-radius: 0 0 20px 0;
}

.Hamburger[opened] span {
  opacity: 1;
  transform: rotate(45deg) translate(-1px, -3px);
  background: $secondary-color;

  &:nth-of-type(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  &:nth-of-type(3) {
    transform: rotate(-45deg) translate(-1px, -2px);
  }
}
</style>
