<template>
  <div>
    <custom-header
      :drawer-opened="this.$store.state.drawerState"
      @ham-click="toggleDrawer"
    >
      <div
        v-for="category in categories"
        slot="menu"
        :key="category.slug"
        :selected="currentCategory === category.slug"
        class="MenuItem"
      >
        <nuxt-link class="MenuLink" :to="'/' + category.slug">
          <span>{{ category.title }}</span>
        </nuxt-link>
      </div>
    </custom-header>
    <div class="SubHeader">
      <social-bar />
      <search-input />
    </div>
    <sidebar :opened="this.$store.state.drawerState">
      <logo slot="header" />
      <div
        v-for="category in categories"
        :key="category.slug"
        :selected="currentCategory === category.slug"
        class="MenuItem"
      >
        <nuxt-link class="MenuLink" :to="'/' + category.slug">
          {{ category.title }}
        </nuxt-link>
      </div>
    </sidebar>
    <nuxt />
    <footer class="MainFooter">
      <div class="Desc">
        <img
          class="Logo"
          src="/assets/images/LogoLongV1White.svg"
          alt="Comiso News"
          draggable="false"
        />
        <div class="Text">
          Comiso News è un sito che raccoglie tutte le notizie presenti su
          Internet che riguardano la città di Comiso (RG) in tempo reale. Su
          Comiso News trovi anche tutti post sei social network, le foto e i
          video su Comiso. Tutto in tempo reale e su un solo sito.
        </div>
      </div>
      <div class="Links">
        <nuxt-link to="/chi-siamo">
          {{ `Cos'è Comiso News` }}
        </nuxt-link>
        <nuxt-link to="/privacy-policy">
          {{ 'Privacy Policy' }}
        </nuxt-link>
      </div>
      <social-bar white></social-bar>
      <div class="Credits">
        {{ credits }}
      </div>
    </footer>
    <div
      class="HiddenLayer"
      :opened="this.$store.state.drawerState"
      @click="toggleDrawer"
    />
  </div>
</template>

<script>
import { CATEGORIES } from '~/lib/consts';
import CustomHeader from '~/components/Header.vue';
import Sidebar from '~/components/Sidebar.vue';
import Logo from '~/components/Logo.vue';
import SocialBar from '~/components/SocialBar.vue';
import SearchInput from '~/components/SearchInput.vue';

export default {
  components: {
    CustomHeader,
    Sidebar,
    Logo,
    SocialBar,
    SearchInput,
  },
  data() {
    return {
      credits: `Comiso News © v${process.env.VERSION}`,
      categories: CATEGORIES,
    };
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${process.env.BASE_URL}${this.$route.path}`,
        },
      ],
    };
  },
  computed: {
    currentCategory() {
      return this.$route.params.category;
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('updateDrawerState', !this.$store.state.drawerState);
    },
  },
};
</script>

<style lang="postcss">
@import '~/assets/typography.pcss';
@import '~/assets/vars.pcss';
@import '~/assets/media.pcss';

body {
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  line-height: 1.5;
  color: var(--main-typography-color, #333);
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  height: 100%;
  min-width: 320px;
  word-spacing: 1px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

#__layout {
  position: relative;
  padding-bottom: $footer-height;
  min-height: 100vh;
}

.Credits {
  margin: 10px 0;
}

.ShareIcons svg {
  cursor: pointer;
  margin: 0 5px;
}

.HiddenLayer {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  transition: opacity 2s;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;

  &[opened] {
    opacity: 1;
    pointer-events: initial;
  }
}

.SubHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: $main-padding;

  @media (--small) {
    margin-top: 0;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }
}

.MenuItem {
  contain: content;
  display: inline-block;
  overflow: hidden;
  position: relative;
  padding: 8px;
  user-select: none;

  & + .MenuItem {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  & a {
    display: block;
    padding: $main-padding;
    margin-top: 0;
    font-size: 2rem;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    color: $white;

    &::after {
      content: none;
    }
  }

  &::after {
    content: '';
    pointer-events: none;
    background-color: $white;
    mix-blend-mode: soft-light;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -50%;
    width: 100%;
    opacity: 0.3;
    transform-origin: 50% 50%;
    transform: skew(-38deg, 0deg) scaleX(0);
    transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover::after,
  &[selected]::after {
    transform: skew(-38deg, 0deg) scaleX(2);
  }
}

.MainFooter {
  text-align: center;
  padding: $main-padding;
  color: $white;
  background-color: $main-color;
  border-radius: 20px 20px 0 0;
  position: absolute;
  width: 100%;
  height: $footer-height;
  bottom: 0;
  left: 0;
  right: 0;

  & .Desc .Text {
    max-width: 320px;
    margin: 0 auto;
    text-align: justify;
    @mixin mediumFont;
  }

  & .Links,
  & .Social {
    text-align: center;
  }

  & .Links {
    @mixin smallFont;

    margin-top: $main-padding;
    margin-bottom: calc($main-padding + 10px);
    text-transform: uppercase;

    & a {
      color: $white;
    }

    & a + a {
      margin-left: 10px;
    }
  }

  & .Logo {
    height: 70px;
    margin: 20px 0;
  }
}

.DesktopMenu {
  display: none;

  .MenuItem {
    a {
      color: $main-color;
      font-size: 1rem;

      &:hover span {
        border-bottom: 2px solid $main-color;
      }
    }

    &[selected] a {
      color: $secondary-color;

      &:hover span {
        border-bottom: none;
      }
    }
  }

  @media (--medium) {
    display: block;
  }
}

ul {
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
}
</style>
