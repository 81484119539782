<template>
  <div class="Container">
    <h1 v-if="error.statusCode === 404">
      {{ 'Pagina non trovata.' }}
    </h1>
    <h1 v-else>
      {{ 'Si è verificato un errore.' }}
    </h1>
    <div class="Action">
      <nuxt-link to="/">
        {{ 'TORNA ALLA HOME' }}
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Error,
      default: new Error(),
    },
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';

.Container {
  padding: 0 $main-padding;
}

.Action {
  margin-top: $main-padding;
}
</style>
