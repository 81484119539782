import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a5338b20 = () => interopDefault(import('../pages/chi-siamo/index.vue' /* webpackChunkName: "pages/chi-siamo/index" */))
const _ba49ddfe = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _2258d152 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _05765ebb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0241df6e = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _05af21fe = () => interopDefault(import('../pages/_category/_slug.vue' /* webpackChunkName: "pages/_category/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chi-siamo",
    component: _a5338b20,
    name: "chi-siamo"
  }, {
    path: "/privacy-policy",
    component: _ba49ddfe,
    name: "privacy-policy"
  }, {
    path: "/search",
    component: _2258d152,
    name: "search"
  }, {
    path: "/",
    component: _05765ebb,
    name: "index"
  }, {
    path: "/:category",
    component: _0241df6e,
    name: "category"
  }, {
    path: "/:category/:slug",
    component: _05af21fe,
    name: "category-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
