export const state = () => ({
  drawerState: false,
  mainArticleLoading: false,
  twitterLoading: false,
  instagramLoading: false,
  flickrLoading: false,
  youtubeLoading: false,
  categoryLoading: false,
  otherArticlesLoading: false,
  searchArticlesLoading: false,
});

export const mutations = {
  updateDrawerState(state, drawerState) {
    state.drawerState = drawerState;
  },
  updateMainArticleLoading(state, loading) {
    state.mainArticleLoading = loading;
  },
  updateTwitterLoading(state, loading) {
    state.twitterLoading = loading;
  },
  updateInstagramLoading(state, loading) {
    state.instagramLoading = loading;
  },
  updateFlickrLoading(state, loading) {
    state.flickrLoading = loading;
  },
  updateYoutubeLoading(state, loading) {
    state.youtubeLoading = loading;
  },
  updateCategoryLoading(state, loading) {
    state.categoryLoading = loading;
  },
  updateOtherArticlesLoading(state, loading) {
    state.otherArticlesLoading = loading;
  },
  updateSearchArticlesLoading(state, loading) {
    state.searchArticlesLoading = loading;
  },
};
