<template>
  <div>
    <img
      v-if="type === 'v1'"
      class="v1Long"
      src="/assets/images/LogoLongV1.svg"
      alt="Comiso News Logo"
    />
    <img
      v-if="type === 'v2'"
      class="v2Long"
      src="/assets/images/LogoLongV2.svg"
      alt="Comiso News Logo"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'v1',
    },
  },
};
</script>

<style lang="postcss">
.v1Long {
  height: 55px;
}
</style>
