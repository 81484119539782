<template>
  <aside class="SideBar" :opened="opened">
    <div class="Menu">
      <nav class="Navigation">
        <slot />
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    opened: Boolean,
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';

.SideBar {
  display: flex;
  position: fixed;
  height: 100vh;
  width: calc(100vw - 20%);
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;

  &[opened] {
    transform: none;
  }
}

.Menu {
  width: 100%;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: $main-color;
  margin-top: 104px;
  border-radius: 0 20px 20px 0;
}

.Navigation {
  display: flex;
  font-size: 0.8rem;
  flex-flow: column wrap;
}

.Header {
  display: flex;
  padding: $main-padding;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: $under-shadow;
  background-color: $white;
}
</style>
